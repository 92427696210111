<template>
  <div
    class="mt-6 text-center"
    data-cy="submit-order"
  >
    <v-btn
      rounded
      color="primary"
      class="d-block d-sm-inline-flex text-wrap"
      depressed
      :disabled="loadingOrderSummary"
      @click="submitOrder"
    >
      {{ $t('productDetails.submitOrderAndProceedToPayment') }}
    </v-btn>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import { CHANGE_PLAN_MODAL_WINDOW_STATE_WAITING_FOR_QUOTATION_CREATION } from '@/constants/app'

export default {
  props: {
    formData: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapState('productDetails', ['loadingOrderSummary'])
  },
  methods: {
    ...mapActions('productDetails', ['createQuotation']),
    ...mapMutations('productDetails', ['setChangePlanModalWindowState']),
    submitOrder () {
      this.setChangePlanModalWindowState(
        CHANGE_PLAN_MODAL_WINDOW_STATE_WAITING_FOR_QUOTATION_CREATION
      )

      const { clientId, contractId } = this.$route.params

      this.createQuotation({ clientId, contractId, ...this.formData })
    }
  }
}
</script>
